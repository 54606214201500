<template>
  <div>
    <!-- Table Families Card -->
    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <b-col
            cols="6"
            md="6"
            class="
          d-flex

          mb-1 mb-md-0
        "
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
          <!-- <label>SortBy</label>
          <v-select
          style="width:200px"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="sortOptions"
            v-model="sort"
            multiple
            :clearable="true"
             />
       -->
          </b-col>
          <b-col
            cols="6"
            class="d-flex align-items-end justify-content-end mb-1"
          >
            <b-button
              variant="outline-primary"
              @click="selectAllRows"
            >
              اختيار الكل
            </b-button>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            cols="12"
            class="d-flex align-items-end justify-content-end mb-1"
          >

            <div
              v-show="selectedItem.length"
              class="align-items-center"
              :class="{ 'd-flex': selectedItem.length }"
            >
              <template>
                <b-button
                  variant="outline-primary"
                  class="mr-1"
                  @click="responseAll(selectedItem, 1)"
                >
                  <span>موافقة</span>
                </b-button>
              </template>

              <b-button
                variant="outline-primary"
                @click="responseAll(selectedItem, 0)"
              >
                <span>رفض</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refTransactionListTable"
        :items="fetchTransaction"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        :current-page="currentPage"
        :per-page="perPage"
        stacked="md"
        select-mode="multi"
        selectable

        :sort-desc.sync="isSortDirDesc"

        :filter="search"
        small
        @row-selected="onRowSelected"
      >
        <!-- Column: User -->
        <!-- <template #cell(user)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="32"
                :src="data.item.avatar"
                :text="avatarText(data.item.fullName)"
                :variant="`light-${resolveUserRoleVariant(data.item.role)}`"
                :to="{ name: 'apps-users-view', params: { id: data.item.id } }"
              />
            </template>
            <b-link
              :to="{ name: 'apps-users-view', params: { id: data.item.id } }"
              class="font-weight-bold d-block text-nowrap"
            >
              {{ data.item.fullName }}
            </b-link>
            <small class="text-muted">@{{ data.item.username }}</small>
          </b-media>
        </template> -->
       
      

        <!-- Column: Actions -->
        <template #cell(action_id)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item
              :to="{ name: 'show-trans-addition', params: { id: data.item.id } }"
            >
              <feather-icon icon="EyeIcon" />
              <span class="align-middle ml-50">عرض</span>
            </b-dropdown-item>
            <b-dropdown-item
              @click="sendResponse(data.item.id,1)"
            >
              <feather-icon icon="CheckIcon" />
              <span class="align-middle ml-50">موافق</span>
            </b-dropdown-item>

            <b-dropdown-item
              @click="sendResponse(data.item.id,0)"
            >
              <feather-icon icon="XIcon" />
              <span class="align-middle ml-50">رفض</span>
            </b-dropdown-item>
          </b-dropdown>
          <b-form-checkbox
            :checked="selectedItem.includes(data.item.id)"
            @change="toggleSelectedMail(data.item.id)"
            @click.native.stop
          />
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span
              class="text-muted"
            >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries</span>
          </b-col>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalUsers"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BFormGroup,
  BInputGroup,
  BInputGroupAppend,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BFormCheckbox,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import store from '@/store'

import usetransactionListList from './usetransactionListList'
import transcationStoreModule from './transactionStoreModule'

export default {
  setup() {
    const {

      selectedItem,
      tableColumns,
      perPage,
      currentPage,

      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refTransactionListTable,
      refetchData,

      fetchTransaction,
      sendResponse,
      responseAll,
      // Extra Filters
    } = usetransactionListList()

    const Transcation_APP_STORE_MODULE_NAME = 'app-transcation'

    // Register module
    if (!store.hasModule(Transcation_APP_STORE_MODULE_NAME)) { store.registerModule(Transcation_APP_STORE_MODULE_NAME, transcationStoreModule) }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(Transcation_APP_STORE_MODULE_NAME)) { store.unregisterModule(Transcation_APP_STORE_MODULE_NAME) }
    })
    // Mail Selection
    // ------------------------------------------------

    const toggleSelectedMail = mailId => {
      const mailIndex = selectedItem.value.indexOf(mailId)

      if (mailIndex === -1) selectedItem.value.push(mailId)
      else selectedItem.value.splice(mailIndex, 1)
    }
    const onRowSelected = items => {
      // console.log(items)
      items.forEach(el => {
        selectedItem.value.push(el.id)
      })
    }
    const selectAllRows = () => {
      if (!selectedItem.value.length > 0) {
        //
        refTransactionListTable.value.clearSelected()
        refTransactionListTable.value.selectAllRows()
      } else {
        refTransactionListTable.value.clearSelected()
      }
    }

    return {
      // Sidebar
      toggleSelectedMail,
      selectedItem,
      search: null,
      selectAllRows,
      fetchTransaction,
      sendResponse,
      responseAll,
      filterOn: [],
      searchTerm: '',
      tableColumns,
      onRowSelected,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refTransactionListTable,
      refetchData,

      // Filter
      avatarText,
    }
  },

  components: {
    BFormGroup,
    BFormCheckbox,
    BInputGroup,
    BInputGroupAppend,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,

    vSelect,
  },
}
</script>

<style scoped>
div {
  color: #7e7a9f;
  font-weight: bold;
  font-transcation: "Cairo", sans-serif;
}
</style>
<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
